export const AUTH_STARTED = "AUTH_STARTED";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_AUTH = "SET_AUTH";
export const REMOVE_AUTH = "REMOVE_AUTH";
export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
//DASHBOARDS CONSTANTS
export const GET_DASHBOARDS_STARTED = "GET_DASHBOARDS_STARTED";
export const GET_DASHBOARDS_SUCCESS = "GET_DASHBOARDS_SUCCESS";
export const GET_DASHBOARDS_ERROR = "GET_DASHBOARDS_ERROR";
// SETTINGS CONSTANTS
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = "FETCH_CHAT_SUCCESS";
export const SELECTED_CHAT = "SELECTED_CHAT";
export const SEARCH_USER = "SEARCH_USER";
export const MSG_SUBMIT = "MSG_SUBMIT";

//  TO DO CONSTANTS
export const FETCH_TODO_REQUEST = "FETCH_TODO_REQUEST";
export const FETCH_TODO_SUCCESS = "FETCH_TODO_SUCCESS";
export const FETCH_TODO_FAILURE = "FETCH_TODO_FAILURE";
export const TOGGLE_STARRED_TODO = "TOGGLE_STARRED_TODO";
export const SET_VISIBILITY_FILTER_TODO = "SET_VISIBILITY_FILTER_TODO";
export const DELETE_TODO = "DELETE_TODO";
export const TODO_DETAILS = "TODO_DETAILS";
export const FILTER_TODO = "FILTER_TODO";
export const COMPLETE_TODO = "COMPLETE_TODO";
export const UPDATE_TODO = "UPDATE_TODO";
export const ADD_TODO = "ADD_TODO";

// CONTACT CONSTANTS
export const MAIN_BOARD_ERROR = "MAIN_BOARD_ERROR";
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";
export const FILTER_CONTACT = "FILTER_CONTACT";
export const TOGGLE_STARRED_CONTACT = "TOGGLE_STARRED_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

// EMAIL CONSTANTS
export const STAR_EMAIL = "STAR_EMAIL";
export const IMPORTANT_EMAIL = "IMPORTANT_EMAIL";
export const OPEN_EMAIL = "OPEN_EMAIL";
export const TRASH_EMAIL = "TRASH_EMAIL";
export const ASSIGN_FOLDER = "ASSIGN_FOLDER";
export const ASSIGN_LABEL = "ASSIGN_LABEL";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const FILTER_EMAIL = "FILTER_EMAIL";
export const SET_EMAIL_VISIBILITY_FILTER = "SET_EMAIL_VISIBILITY_FILTER";

// NOTES CONSTANTS
export const SELECTED_NOTES = "SELECTED_NOTES";
export const SEARCH_NOTES = "SEARCH_NOTES";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const ADD_NOTE = "ADD_NOTE";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";

// ERRORS COSTANTS
export const PUBLIC_ERROR_RECEIVED = "PUBLIC_ERROR_RECEIVED";
export const PUBLIC_ERROR_EMPTY = "PUBLIC_ERROR_EMPTY";

// Global Timer CONSTANTS
export const START_TIMER = "START_TIMER";
export const STOP_TIMER = "STOP_TIMER";
export const RESET_TIMER = "RESET_TIMER";

// Dataset CONSTANTS
export const DATASET_NOTIFICATION = "DATASET_NOTIFICATION";
// GET CONSTANTS INFO
export const GET_DATASET_INFO_STARTED = "GET_DATASET_INFO_STARTED";
export const GET_DATASET_INFO_SUCCESS = "GET_DATASET_INFO_SUCCESS";
export const GET_DATASET_INFO_ERROR = "GET_DATASET_INFO_ERROR";
// GET CONSTANTS
export const GET_DATASET_STARTED = "GET_DATASET_STARTED";
export const GET_DATASET_SUCCESS = "GET_DATASET_SUCCESS";
export const GET_DATASET_ERROR = "GET_DATASET_ERROR";
// POST CONSTANTS
export const POST_DATASET_STARTED = "POST_DATASET_STARTED";
export const POST_DATASET_SUCCESS = "POST_DATASET_SUCCESS";
export const POST_DATASET_ERROR = "POST_DATASET_ERROR";
// PUT CONSTANTS
export const PUT_DATASET_STARTED = "PUT_DATASET_STARTED";
export const PUT_DATASET_SUCCESS = "PUT_DATASET_SUCCESS";
export const PUT_DATASET_ERROR = "PUT_DATASET_ERROR";
// DELETE CONSTANTS
export const DELETE_DATASET_STARTED = "DELETE_DATASET_STARTED";
export const DELETE_DATASET_SUCCESS = "DELETE_DATASET_SUCCESS";
export const DELETE_DATASET_ERROR = "DELETE_DATASET_ERROR";

export const ADD_DATASET_FILE = "ADD_DATASET_FILE";
export const DELETE_DATASET_FILE = "DELETE_DATASET_FILE";
export const SET_DATASET_FILE = "SET_DATASET_FILE";
export const CLEAR_DATASET = "CLEAR_DATASET";
export const CURRENT_USER = "C_USER";
export const USER_PROFILE = "P_USER";
export const USERNAME = "N_USER";
export const TOKEN = "SN";
export const REFRESH_TOKEN = "RF_SN";
export const CONNECTION_ID = "CN_ID";
export const AUTH_TOKEN = "Authorization";

// DatasetFiles CONSTANTS
// GET CONSTANTS
export const GET_DATASET_FILES_STARTED = "GET_DATASET_FILES_STARTED";
export const GET_DATASET_FILES_SUCCESS = "GET_DATASET_FILES_SUCCESS";
export const GET_DATASET_FILES_ERROR = "GET_DATASET_FILES_ERROR";
export const SET_METRICS = "SET_METRICS";
// POST CONSTANTS
export const POST_DATASET_FILES_STARTED = "POST_DATASET_FILES_STARTED";
export const POST_DATASET_FILES_SUCCESS = "POST_DATASET_FILES_SUCCESS";
export const POST_DATASET_FILES_ERROR = "POST_DATASET_FILES_ERROR";

// DatasetVersions CONSTANTS
// GET DATA CONSTANTS
export const GET_DATASET_VERSION_DATA_STARTED =
  "GET_DATASET_VERSION_DATA_STARTED";
export const GET_DATASET_VERSION_DATA_SUCCESS =
  "GET_DATASET_VERSION_DATA_SUCCESS";
export const GET_DATASET_VERSION_DATA_ERROR = "GET_DATASET_VERSION_DATA_ERROR";

export const GET_DATASET_VERSION_DATA_CLEANING_STARTED =
  "GET_DATASET_VERSION_DATA_CLEANING_STARTED";
export const GET_DATASET_VERSION_DATA_CLEANING_SUCCESS =
  "GET_DATASET_VERSION_DATA_CLEANING_SUCCESS";
export const GET_DATASET_VERSION_DATA_CLEANING_ERROR =
  "GET_DATASET_VERSION_DATA_CLEANING_ERROR";

export const CLEAR_DATASET_VERSION_DATA = "CLEAR_DATASET_VERSION_DATA";
// GET CONSTANTS
export const GET_DATASET_VERSIONS_STARTED = "GET_DATASET_VERSIONS_STARTED";
export const GET_DATASET_VERSIONS_SUCCESS = "GET_DATASET_VERSIONS_SUCCESS";
export const GET_DATASET_VERSIONS_ERROR = "GET_DATASET_VERSIONS_ERROR";

//GET Dataset Version Detail Data
export const GET_DATASET_VERSION_DETAIL_DATA_STARTED =
  "GET_DATASET_VERSION_DETAIL_DATA_STARTED";
export const GET_DATASET_VERSION_DETAIL_DATA_SUCCESS =
  "GET_DATASET_VERSION_DETAIL_DATA_SUCCESS";
export const GET_DATASET_VERSION_DETAIL_DATA_ERROR =
  "GET_DATASET_VERSION_DETAIL_DATA_ERROR";

// POST CONSTANTS
export const POST_DATASET_VERSIONS_STARTED = "POST_DATASET_VERSIONS_STARTED";
export const POST_DATASET_VERSIONS_SUCCESS = "POST_DATASET_VERSIONS_SUCCESS";
export const POST_DATASET_VERSIONS_ERROR = "POST_DATASET_VERSIONS_ERROR";

// Salma
export const GET_CHART_DATA_STARTED = "GET_CHART_DATA_STARTED";
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS";
export const GET_CHART_DATA_ERROR = "GET_CHART_DATA_ERROR";

// yazeed
// GET CONSTANTS
export const GET_PEOPLE_STARTED = "GET_PEOPLE_STARTED";
export const GET_PEOPLE_SUCCESS = "GET_PEOPLE_SUCCESS";
export const GET_PEOPLE_ERROR = "GET_PEOPLE_ERROR";
// GET CONSTANTS
export const GET_PERSON_BYID_STARTED = "GET_PERSON_BYID_STARTED";
export const GET_PERSON_BYID_SUCCESS = "GET_PERSON_BYID_SUCCESS";
export const GET_PERSON_BYID_ERROR = "GET_PERSON_BYID_ERROR";
// POST CONSTANTS
export const POST_PERSON_STARTED = "POST_PERSON_STARTED";
export const POST_PERSON_SUCCESS = "POST_PERSON_SUCCESS";
export const POST_PERSON_ERROR = "POST_PERSON_ERROR";
// PUT CONSTANTS
export const PUT_PERSON_STARTED = "PUT_PERSON_STARTED";
export const PUT_PERSON_SUCCESS = "PUT_PERSON_SUCCESS";
export const PUT_PERSON_ERROR = "PUT_PERSON_ERROR";
// DELETE CONSTANTS
export const DELETE_PERSON_STARTED = "DELETE_PERSON_STARTED";
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS";
export const DELETE_PERSON_ERROR = "DELETE_PERSON_ERROR";

// ML Engine
// POST CONSTANTS
export const POST_TRAIN_STARTED = "POST_TRAIN_STARTED";
export const POST_TRAIN_SUCCESS = "POST_TRAIN_SUCCESS";
export const POST_TRAIN_ERROR = "POST_TRAIN_ERROR";

export const GET_ALGORITHM_TYPES_STARTED = "GET_ALGORITHM_TYPES_STARTED";
export const GET_ALGORITHM_TYPES_SUCCESS = "GET_ALGORITHM_TYPES_SUCCESS";
export const GET_ALGORITHM_TYPES_ERROR = "GET_ALGORITHM_TYPES_ERROR";

export const GET_ALGORITHMS_STARTED = "GET_ALGORITHMS_STARTED";
export const GET_ALGORITHMS_SUCCESS = "GET_ALGORITHMS_SUCCESS";
export const GET_ALGORITHMS_ERROR = "GET_ALGORITHMS_ERROR";
export const CLEAR_WORKSPACE_MODALS = "CLEAR_WORKSPACE_MODALS";

export const GET_ALGORITHM_MODELS_STARTED = "GET_ALGORITHM_MODELS_STARTED";
export const GET_ALGORITHM_MODELS_SUCCESS = "GET_ALGORITHM_MODELS_SUCCESS";
export const GET_ALGORITHM_MODELS_ERROR = "GET_ALGORITHM_MODELS_ERROR";

// workspace
// GET CONSTANTS
export const GET_WORKSPACES_STARTED = "GET_WORKSPACES_STARTED";
export const GET_WORKSPACES_SUCCESS = "GET_WORKSPACES_SUCCESS";
export const GET_WORKSPACES_ERROR = "GET_WORKSPACES_ERROR";
// GET SHARED
export const GET_WORKSPACE_STATS_STARTED = "GET_WORKSPACE_STATS_STARTED";
export const GET_WORKSPACE_STATS_SUCCESS = "GET_WORKSPACE_STATS_SUCCESS";
export const GET_WORKSPACE_STATS_ERROR = "GET_WORKSPACE_STATS_ERROR";

export const GET_SHARED_WORKSPACES_STARTED = "GET_SHARED_WORKSPACES_STARTED";
export const GET_SHARED_WORKSPACES_SUCCESS = "GET_SHARED_WORKSPACES_SUCCESS";
export const GET_SHARED_WORKSPACES_ERROR = "GET_SHARED_WORKSPACES_ERROR";
// GET MOEDL
export const GET_WORKSPACE_MODEL_STARTED = "GET_WORKSPACE_MODEL_STARTED";
export const GET_WORKSPACE_MODEL_SUCCESS = "GET_WORKSPACES_MODEL_SUCCESS";
export const GET_WORKSPACE_MODEL_ERROR = "GET_WORKSPACE_MODEL_ERROR";

// GET CONSTANTS
export const GET_WORKSPACE_BYID_STARTED = "GET_WORKSPACE_BYID_STARTED";
export const GET_WORKSPACE_BYID_SUCCESS = "GET_WORKSPACE_BYID_SUCCESS";
export const GET_WORKSPACE_BYID_ERROR = "GET_WORKSPACE_BYID_ERROR";
// POST CONSTANTS
export const POST_WORKSPACE_STARTED = "POST_WORKSPACE_STARTED";
export const POST_WORKSPACE_SUCCESS = "POST_WORKSPACE_SUCCESS";
export const POST_WORKSPACE_ERROR = "POST_WORKSPACE_ERROR";
// PUT CONSTANTS
export const PUT_WORKSPACE_STARTED = "PUT_WORKSPACE_STARTED";
export const PUT_WORKSPACE_SUCCESS = "PUT_WORKSPACE_SUCCESS";
export const PUT_WORKSPACE_ERROR = "PUT_WORKSPACE_ERROR";
// DELETE CONSTANTS
export const DELETE_WORKSPACE_STARTED = "DELETE_WORKSPACE_STARTED";
export const DELETE_WORKSPACE_SUCCESS = "DELETE_WORKSPACE_SUCCESS";
export const DELETE_WORKSPACE_ERROR = "DELETE_WORKSPACE_ERROR";

export const SET_DATA_MAPPING = "SET_DATA_MAPPING";
export const CLEAR_DATA_MAPPING = "CLEAR_DATA_MAPPING";
// ANALYTICS CARDS
export const PUT_ANALYTICS_CARD_STARTED = "PUT_ANALYTICS_CARD_STARTED";
export const PUT_ANALYTICS_CARD_SUCCESS = "PUT_ANALYTICS_CARD_SUCCESS";
export const PUT_ANALYTICS_CARD_ERROR = "PUT_ANALYTICS_CARD_ERROR";

export const SET_ACTIVE_HTAB = "SET_ACTIVE_HTAB";

export const OPEN_GLOBAL_MODAL = "OPEN_GLOBAL_MODAL";
export const CLEAR_GLOBAL_MODAL = "CLEAR_GLOBAL_MODAL";
export const RENAME_TAB = "RENAME_TAB";
export const SET_HTAB = "SET_HTAB";
export const SET_SAVED_HTABS = "SET_SAVED_HTABS";
export const SET_SAVED_HTABS_ERROR = "SET_SAVED_HTABS_ERROR";
export const SET_ANALYSIS_TYPES = "SET_ANALYSIS_TYPES";
export const SET_ANALYSIS_TYPES_ERROR = "SET_ANALYSIS_TYPES_ERROR";
export const REMOVE_CHILD_ANALYSIS_FROM_CONTAINER =
  "REMOVE_CHILD_ANALYSIS_FROM_CONTAINER";
export const START_SAVING_ANALYSIS = "START_SAVING_ANALYSIS";
export const STOP_SAVING_ANALYSIS = "STOP_SAVING_ANALYSIS";
export const UPDATE_TAB_AND_ANALYSIS = "UPDATE_TAB_AND_ANALYSIS";
export const UPDATE_MODIFIED_QUEUE = "UPDATE_MODIFIED_QUEUE";
export const SET_DIRTY_FALSE = "SET_DIRTY_FALSE";
export const ADD_ANALYSIS = "ADD_ANALYSIS";
export const ADD_BULK_ANALYSIS = "ADD_BULK_ANALYSIS";
export const REMOVE_ANALYSIS = "REMOVE_ANALYSIS";
export const CLEAR_ANALYTICS = "CLEAR_ANALYTICS";
export const GET_ANALYTICS_STARTED = "GET_ANALYTICS_STARTED";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTIC_SUCCESS = "GET_ANALYTIC_SUCCESS";

export const GET_ANALYTICS_ERROR = "GET_ANALYTICS_ERROR";

export const SET_DATA_ANALYTICS = "SET_DATA_ANALYTICS";

export const ADD_PINNED_CHART = "ADD_PINNED_CHART";
export const UPDATE_PINNED_CHART = "UPDATE_PINNED_CHART";
export const UPDATE_ANALYSIS = "UPDATE_ANALYSIS";
export const REMOVE_PINNED_CHART = "REMOVE_PINNED_CHART";
export const CLEAR_PINNED_CHARTS = "CLEAR_PINNED_CHARTS";
export const APPEND_CHART_TO_CONTAINER = "APPEND_CHART_TO_CONTAINER";

//SIGNAL_R_STARTED
export const SIGNAL_R_STARTED = "SIGNAL_R_STARTED";
export const SIGNAL_R_SUCCESS = "SIGNAL_R_SUCCESS";
export const SIGNAL_R_ERROR = "SIGNAL_R_ERROR";
export const SIGNAL_R_RESET = "SIGNAL_R_RESET";

//USER Helper
export const USER_HELPER_ON = "USER_HELPER_ON";
export const USER_HELPER_OFF = "USER_HELPER_OFF";
export const USER_HELPER_RESET = "USER_HELPER_RESET";
export const USER_HELPER_COMPLETED = "USER_HELPER_COMPLETED";
export const USER_HELPER_UPDATE = "USER_HELPER_UPDATE";
export const USER_HELPER_NEXT = "USER_HELPER_NEXT";

// Dataset CONSTANTS
// GET CONSTANTS
export const GET_USER_NOTIFICATIONS_STARTED = "GET_USER_NOTIFICATIONS_STARTED";
export const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";
export const GET_USER_NOTIFICATIONS_ERROR = "GET_USER_NOTIFICATIONS_ERROR";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
//DATATABLE CONSTANTS
export const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";

//DELETE MODAL CONSTANTS
export const SET_DELETE_MODAL_DATA = "SET_DELETE_MODAL_DATA";
export const CLEAR_DELETE_MODAL_DATA = "CLEAR_DELETE_MODAL_DATA";
//Success MODAL CONSTANTS
export const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
export const CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL";

// FILE UPLOADER CONSTANTS
export const ADD_FILES = "ADD_FILES";
export const REMOVE_FILE = "REMOVE_FILE";
export const CLEAR_VALUES = "CLEAR_VALUES";
export const UPDATE_FILE = "UPDATE_FILE";
export const FILE_COMPLETED = "FILE_COMPLETED";
export const FILE_FAILED = "FILE_FAILED";

//Data Manipulation
export const ADD_DATA_MANIPULATION = "ADD_DATA_MANIPULATION";
export const RESET_DATA_MANIPULATION = "RESET_DATA_MANIPULATION";
