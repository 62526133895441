import {
  GET_DASHBOARDS_STARTED,
  GET_DASHBOARDS_SUCCESS,
  GET_DASHBOARDS_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const DashboardsReducer = (
  state = initialStates.dashboards,
  { type, payload, pagination }
) => {
  switch (type) {
    case GET_DASHBOARDS_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DASHBOARDS_SUCCESS:
      return {
        ...state,
        data: payload,
        pagination,
        error: null,
        loading: false,
      };
    case GET_DASHBOARDS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default DashboardsReducer;
